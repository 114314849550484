import React, { useEffect, useState } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
const queryString = require("query-string")

const OrderConfirmation = props => {
  const parsed = queryString.parse(props.location.search)

  const [loading, setLoading] = useState(true)
  const [storeInfo, setStoreInfo] = useState(null)
  const [orderInfo, setOrderInfo] = useState(null)

  useEffect(() => {
    if (parsed?.order_id) {
      getStoreInfo()
        .then(async response => {
          setStoreInfo(response)
          const order_info = await getOrderInfo(parsed?.order_id)
          console.log(order_info)
          setOrderInfo(order_info)
        })
        .catch(error => {
          console.error(error)
        })
        .finally(() => setLoading(false))
    } else {
      window.location.replace("/")
    }
  }, [parsed?.order_id])

  function getStoreInfo() {
    return fetch(`/.netlify/functions/bigcommerce?endpoint=/v2/store`, {
      credentials: "same-origin",
      mode: "same-origin",
    }).then(res => res.json())
  }

  function getOrderInfo(order_id) {
    return fetch(
      `/.netlify/functions/bigcommerce?endpoint=/v2/orders/${order_id}`,
      { credentials: "same-origin", mode: "same-origin" }
    ).then(res => res.json())
  }

  return (
    <Layout>
      <SEO title={"Confirmed Order"} />
      <div className="page-headline">
        <div className="container text-center w-100" style={{ maxWidth: 500 }}>
          {loading ? (
            <h5>Loading..</h5>
          ) : (
            <>
              <h1>{`Thank you ${orderInfo?.billing_address?.first_name}!`}</h1>
              <p>
                Your order number is{" "}
                <span className="font-weight-bold">{parsed?.order_id}</span>
              </p>
              <p>
                {`An email will be sent containing information about your
                purchase. If you have any questions about your purchase, email
                us at ${storeInfo?.admin_email}.`}
              </p>

              <hr />

              {/* <a href="/" className="btn btn-outline-dark">
                continue shopping
              </a> */}
            </>
          )}
        </div>
      </div>
    </Layout>
  )
}

export default OrderConfirmation
